export const ReadingStrategiesIcon = ({ className = '' }) => {
  return (
    <div className={className}>
      <svg width='100%' height='100%' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M23.7404 8.20959L23.6892 8.15833H23.6167H8.33333H8.15833V8.33333V31.6667V31.8417H8.33333H31.6667H31.8417V31.6667V16.3833V16.3108L31.7904 16.2596L23.7404 8.20959ZM33.8929 14.1571L33.8929 14.1571L33.8946 14.1587C34.4933 14.7416 34.825 15.5461 34.825 16.3833V31.6667C34.825 33.4034 33.4034 34.825 31.6667 34.825H8.33333C6.59665 34.825 5.175 33.4034 5.175 31.6667V8.33333C5.175 6.59665 6.59665 5.175 8.33333 5.175H23.6167C24.4539 5.175 25.2584 5.50674 25.8413 6.10543L25.8429 6.10708L33.8929 14.1571ZM11.8417 25.175H28.1583V28.1583H11.8417V25.175ZM11.8417 18.5083H28.1583V21.4917H11.8417V18.5083ZM11.8417 11.8417H23.1583V14.825H11.8417V11.8417Z'
          stroke='white'
          strokeWidth='0.35'
        />
      </svg>
    </div>
  );
};
