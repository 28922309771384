export const VocabularyIcon = ({ className = '' }) => {
  return (
    <div className={className}>
      <svg width="100%" height="100%" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 6H2.5V20C2.5 21.1 3.4 22 4.5 22H18.5V20H4.5V6Z" />
        <path d="M20.5 2H8.5C7.4 2 6.5 2.9 6.5 4V16C6.5 17.1 7.4 18 8.5 18H20.5C21.6 18 22.5 17.1 22.5 16V4C22.5 2.9 21.6 2 20.5 2ZM20.5 16H8.5V4H20.5V16Z" />
        <path d="M12.69 12.2H16.32L17.12 14.5H18.68L15.3 5.5H13.7L10.32 14.5H11.88L12.69 12.2ZM14.46 7.17H14.54L15.85 10.89H13.16L14.46 7.17Z" />
      </svg>

    </div>
  );
};
