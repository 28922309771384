import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import ModalX from '../ModalX';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import Markdown from 'react-markdown';

// Wrapper to provide a basic but styled modal dialog

// Props:
// icon: JSX.Element (optional)
// title: string (optional)
// children: JSX.Element
// showModal: boolean
// setShowModal: function

// Example usage:
// const [isFormError, setIsFormError] = useState(false);
// <BasicModal
//   icon={<ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
//   title="Error"
//   showModal={isFormError}
//   setShowModal={setIsFormError}
// >
//   <div className="text-sm text-gray-500">
//     {errorMessage}
//   </div>
// </BasicModal>

export default function BasicModal({
  icon,
  title,
  markdownTitle = false,
  children,
  showModal,
  setShowModal,
  iconBackground,
  initialFocusRef,
  onBackButtonClicked,
  backButtonCopy,
  dialogPadding = 'px-4 pb-4 pt-5 sm:p-6',
  maxWidth = 'sm:max-w-lg',
  maxHeight = 'max-h-[95vh]',
  overflowY = 'overflow-y-auto',
  isExitable = true,
}) {
  const concatenateClasses = (classes) => {
    return classes.join(' ');
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-40 print:hidden'
        initialFocus={initialFocusRef}
        onClose={() => {
          if (isExitable) {
            setShowModal(false);
          }
        }}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel
                className={`transform rounded-lg text-left shadow-xl transition-all ${maxHeight} sm:w-full bg-white ${maxWidth} ${dialogPadding || ''} ${overflowY}`}>
                {isExitable && <ModalX setOpen={setShowModal} />}
                <div>
                  {onBackButtonClicked && (
                    <div className='absolute top-6 left-4 w-fit '>
                      <button
                        className='w-fit flex flex-row items-center cursor-pointer rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'
                        onClick={onBackButtonClicked}>
                        <ArrowLeftIcon className='h-5 w-5 ' />
                        <span className='hidden sm:block text-xs'>{backButtonCopy}</span>
                        {/* Back to Preview/Search */}
                      </button>
                    </div>
                  )}
                  {icon && (
                    <div
                      className={concatenateClasses([
                        'mx-auto flex h-12 w-12 items-center justify-center rounded-full',
                        iconBackground || 'bg-green-100',
                      ])}>
                      {icon}
                    </div>
                  )}
                  {title && (
                    <div className='mt-1 text-center sm:mt-2'>
                      <Dialog.Title
                        as='h3'
                        className={`mb-2 text-base leading-6 text-gray-900 ${markdownTitle ? '' : 'font-semibold'}`}>
                        {markdownTitle ? <Markdown>{title}</Markdown> : title}
                      </Dialog.Title>
                    </div>
                  )}
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
