import CreateAxiosInstance from './axiosConfig';
const APIClient = CreateAxiosInstance(import.meta.env.VITE_API_BASE_URL);

export const templateConstants = {
  SUBJECTS: ['All Subjects', 'English Language Arts', 'Social Studies', 'Science', 'Art'],
  GRADE_LEVELS: [
    'All Grades',
    'K',
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
  ],
  // what we call the formats in our data models
  FORMATS: ['Printable', 'Digital'],
  // what we call the formats in the UI
  FORMAT_NAMES: ['Workbook', 'Slides'],
  // lookup table to convert between the two
  FORMAT_NAME_TO_TYPE: {
    Workbook: 'Printable',
    Slides: 'Digital',
  },
  // Only including ELA anchor standards for now
  STANDARDS: [
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.1',
      strand: 'Key Ideas and Details',
      description:
        'Read closely to determine what the text says explicitly and to make logical inferences from it; cite specific textual evidence when writing or speaking to support conclusions drawn from the text.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.2',
      strand: 'Key Ideas and Details',
      description:
        'Determine central ideas or themes of a text and analyze their development; summarize the key supporting details and ideas.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.3',
      strand: 'Key Ideas and Details',
      description:
        'Analyze how and why individuals, events, and ideas develop and interact over the course of a text.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.4',
      strand: 'Craft and Structure',
      description:
        'Interpret words and phrases as they are used in a text, including determining technical, connotative, and figurative meanings, and analyze how specific word choices shape meaning or tone.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.5',
      strand: 'Craft and Structure',
      description:
        'Analyze the structure of texts, including how specific sentences, paragraphs, and larger portions of the text (e.g., a section, chapter, scene, or stanza) relate to each other and the whole.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.6',
      strand: 'Craft and Structure',
      description: 'Assess how point of view or purpose shapes the content and style of a text.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.7',
      strand: 'Integration of Knowledge and Ideas',
      description:
        'Integrate and evaluate content presented in diverse media and formats, including visually and quantitatively, aswell as in words.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.8',
      strand: 'Integration of Knowledge and Ideas',
      description:
        'Delineate and evaluate the argument and specific claims in a text, including the validity of the reasoning as well as the relevance and sufficiency of the evidence.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.9',
      strand: 'Integration of Knowledge and Ideas',
      description:
        'Analyze how two or more texts address similar themes or topics in order to build knowledge or to compare the approaches the authors take.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Reading',
      id: 'CCRA.R.10',
      strand: 'Range of Reading and Level of Text Complexity',
      description:
        'Read and comprehend complex literary and informational texts independently and proficiently.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.1',
      strand: 'Text Types and Purposes',
      description:
        'Write arguments to support claims in an analysis of substantive topics or texts, using valid reasoning and relevant and sufficient evidence.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.2',
      strand: 'Text Types and Purposes',
      description:
        'Write informative/explanatory texts to examine and convey complex ideas and information clearly and accurately through the effective selection, organization, and analysis of content.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.3',
      strand: 'Text Types and Purposes',
      description:
        'Write narratives to develop real or imagined experiences or events using effective technique, well-chosen details, and well-structured event sequences.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.4',
      strand: 'Production and Distribution of Writing',
      description:
        'Produce clear and coherent writing in which the development, organization, and style are appropriate to task, purpose, and audience.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.5',
      strand: 'Production and Distribution of Writing',
      description:
        'Develop and strengthen writing as needed by planning, revising, editing, rewriting, or trying a new approach.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.6',
      strand: 'Production and Distribution of Writing',
      description:
        'Use technology, including the Internet, to produce and publish writing and to interact and collaborate with others.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.7',
      strand: 'Research to Build and Present Knowledge',
      description:
        'Conduct short as well as more sustained research projects based on focused questions, demonstrating understanding of the subject under investigation.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.8',
      strand: 'Research to Build and Present Knowledge',
      description:
        'Gather relevant information from multiple print and digital sources, assess the credibility and accuracy of each source, and integrate the information while avoiding plagiarism.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.9',
      strand: 'Research to Build and Present Knowledge',
      description:
        'Draw evidence from literary or informational texts to support analysis, reflection, and research.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Writing',
      id: 'CCRA.W.10',
      strand: 'Range of Writing',
      description:
        '. Write routinely over extended time frames (time for research, reflection, and revision) and shorter time frames (a single sitting or a day or two) for a range of tasks, purposes, and audiences.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Language',
      id: 'CCRA.L.1',
      strand: 'Conventions of Standard English',
      description:
        'Demonstrate command of the conventions of standard English grammar and usage when writing or speaking.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Language',
      id: 'CCRA.L.2',
      strand: 'Conventions of Standard English',
      description:
        'Demonstrate command of the conventions of standard English capitalization, punctuation, and spelling when writing.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Language',
      id: 'CCRA.L.3',
      strand: 'Knowledge of Language',
      description:
        'Apply knowledge of language to understand how language functions in different contexts, to make effective choices for meaning or style, and to comprehend more fully when reading or listening.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Language',
      id: 'CCRA.L.4',
      strand: 'Vocabulary Acquisition and Use',
      description:
        'Determine or clarify the meaning of unknown and multiple-meaning words and phrases by using context clues, analyzing meaningful word parts, and consulting general and specialized reference materials, as appropriate.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Language',
      id: 'CCRA.L.5',
      strand: 'Vocabulary Acquisition and Use',
      description:
        'Demonstrate understanding of figurative language, word relationships, and nuances in word meanings.',
    },
    {
      domain: 'College and Career Readiness Anchor Standards for Language',
      id: 'CCRA.L.6',
      strand: 'Vocabulary Acquisition and Use',
      description:
        'Acquire and use accurately a range of general academic and domain-specific words and phrases sufficient for reading, writing, speaking, and listening at the college and career readiness level; demonstrate independence in gathering vocabulary knowledge when encountering an unknown term important to comprehension or expression.',
    },
  ],
  ACTIVITY_CATEGORIES: [
    {
      name: 'Summarize Learning',
      bgColor: 'bg-indigo-50',
      borderColor: 'ring-indigo-700/50',
      textColor: 'text-indigo-700',
      underlineColor: 'border-indigo-500',
    },
    {
      name: 'Vocabulary',
      bgColor: 'bg-fuchsia-50',
      borderColor: 'ring-fuchsia-700/50',
      textColor: 'text-fuchsia-700',
      underlineColor: 'border-fuchsia-500',
    },
    {
      name: 'Reading Strategies',
      bgColor: 'bg-rose-50',
      borderColor: 'ring-rose-700/50',
      textColor: 'text-rose-700',
      underlineColor: 'border-rose-500',
    },
    {
      name: 'Writing Strategies',
      bgColor: 'bg-orange-50',
      borderColor: 'ring-orange-700/50',
      textColor: 'text-orange-700',
      underlineColor: 'border-orange-500',
    },
    {
      name: 'Text Analysis',
      bgColor: 'bg-lime-50',
      borderColor: 'ring-lime-700/50',
      textColor: 'text-lime-700',
      underlineColor: 'border-lime-500',
    },
    {
      name: 'Collaboration',
      bgColor: 'bg-teal-50',
      borderColor: 'ring-teal-700/50',
      textColor: 'text-teal-700',
      underlineColor: 'border-teal-500',
    },
    {
      name: 'Critical Thinking',
      bgColor: 'bg-sky-50',
      borderColor: 'ring-sky-700/50',
      textColor: 'text-sky-700',
      underlineColor: 'border-sky-500',
    },
    {
      name: 'Basics',
      bgColor: 'bg-sky-50',
      borderColor: 'ring-sky-700/50',
      textColor: 'text-sky-700',
      underlineColor: 'border-sky-500',
    },
    {
      name: 'Vocabulary With Images',
      bgColor: 'bg-sky-50',
      borderColor: 'ring-sky-700/50',
      textColor: 'text-sky-700',
      underlineColor: 'border-sky-500',
    },
  ],
  BLOCK_TYPES: [
    {
      name: 'Graphic Organizer',
      bgColor: 'bg-indigo-50',
      borderColor: 'ring-indigo-700/50',
      textColor: 'text-indigo-700',
      underlineColor: 'border-indigo-500',
    },
    {
      name: 'Reading',
      bgColor: 'bg-fuchsia-50',
      borderColor: 'ring-fuchsia-700/50',
      textColor: 'text-fuchsia-700',
      underlineColor: 'border-fuchsia-500',
    },
    {
      name: 'Short Answer',
      bgColor: 'bg-rose-50',
      borderColor: 'ring-rose-700/50',
      textColor: 'text-rose-700',
      underlineColor: 'border-rose-500',
    },
    {
      name: 'Vocabulary',
      bgColor: 'bg-orange-50',
      borderColor: 'ring-orange-700/50',
      textColor: 'text-orange-700',
      underlineColor: 'border-orange-500',
    },
    {
      name: 'Open-ended',
      bgColor: 'bg-lime-50',
      borderColor: 'ring-lime-700/50',
      textColor: 'text-lime-700',
      underlineColor: 'border-lime-500',
    },
    {
      name: 'Instructions',
      bgColor: 'bg-teal-50',
      borderColor: 'ring-teal-700/50',
      textColor: 'text-teal-700',
      underlineColor: 'border-teal-500',
    },
    {
      name: 'Image',
      bgColor: 'bg-sky-50',
      borderColor: 'ring-sky-700/50',
      textColor: 'text-sky-700',
      underlineColor: 'border-sky-500',
    },
    {
      name: 'Multiple Choice',
      bgColor: 'bg-emerald-50',
      borderColor: 'ring-emerald-700/50',
      textColor: 'text-emerald-700',
      underlineColor: 'border-emerald-500',
    },
    {
      name: 'Take Notes',
      bgColor: 'bg-yellow-50',
      borderColor: 'ring-yellow-700/50',
      textColor: 'text-yellow-700',
      underlineColor: 'border-yellow-500',
    },
    {
      name: 'Interactive',
      bgColor: 'bg-orange-50',
      borderColor: 'ring-orange-700/50',
      textColor: 'text-orange-700',
      underlineColor: 'border-orange-500',
    },
  ],
  BLOCK_TYPE_TO_RESOURCE_DIV: {
    Reading: 'adaptation-container',
    'Short Answer': 'short-container',
    Vocabulary: 'vocab-container',
    'Open-ended': 'prompts-container',
    'Multiple Choice': 'questions-container',
    'Take Notes': 'adaptation-container',
  },
};

export async function getTemplate(templateId) {
  return await APIClient.get('/template/' + templateId, {
    customErrorHandler: function (error) {
      alert('Error getting template:\n' + error?.response?.data?.error);
    },
  });
}

export async function getTemplates({
  templateFilters,
  searchText,
  page,
  featuredOnly,
  favoriteOnly,
  returnAll,
}) {
  let queryParametersString = '';
  if (templateFilters) {
    queryParametersString = '?';
    Object.keys(templateFilters).forEach((key, index) => {
      queryParametersString += `${key}=${templateFilters[key]}`;
      if (index < Object.keys(templateFilters).length - 1) {
        queryParametersString += '&';
      }
    });
  }
  if (searchText) {
    queryParametersString += `${queryParametersString ? '&' : '?'}search=${searchText}`;
  }
  if (page) {
    queryParametersString += `${queryParametersString ? '&' : '?'}page=${page}`;
  }
  if (featuredOnly) {
    queryParametersString += `${queryParametersString ? '&' : '?'}featured=${featuredOnly}`;
  }
  if (favoriteOnly) {
    queryParametersString += `${queryParametersString ? '&' : '?'}favorites=${favoriteOnly}`;
  }
  if (returnAll) {
    queryParametersString += `${queryParametersString ? '&' : '?'}return_all=${returnAll}`;
  }

  return await APIClient.get(`/template${queryParametersString}`, {
    customErrorHandler: function (error) {
      alert('Error getting templates:\n' + error?.response?.data?.error);
    },
  });
}

export async function getMyTemplates(templateType) {
  return await APIClient.get(
    `/template/myTemplates${templateType ? '?templateType=' + templateType : ''}`,
    {
      customErrorHandler: function (error) {
        alert('Error getting templates:\n' + error?.response?.data?.error);
      },
    }
  );
}

export async function getTemplateStructure(templateId, packetId=null, tempBlocks=null) {
  
  // Build query parameters
  const queryParams = new URLSearchParams();
  if (packetId) queryParams.append('packetId', packetId);
  if (tempBlocks) queryParams.append('tempBlocks', tempBlocks);
  
  // Construct URL with query parameters if they exist
  const queryString = queryParams.toString();
  const url = `/template/${templateId}/structure${queryString ? '?' + queryString : ''}`;
  
  return await APIClient.get(url, {
    customErrorHandler: function (error) {
      alert('Error getting template structure:\n' + error?.response?.data?.error);
    },
  });
}

export async function createUserTemplate(title, templateBlocks, existingTemplateId) {
  let data = {
    title,
    template_blocks: templateBlocks,
    existing_template_id: existingTemplateId,
  };
  return await APIClient.post('/template/user', data, {
    customErrorHandler: function (error) {
      alert('Error creating user template:\n' + error?.response?.data?.error);
    },
  });
}

export async function deleteTemplate(templateId) {
  return await APIClient.delete(`/template/${templateId}`, {
    customErrorHandler: function (error) {
      alert('Error deleting template:\n' + error?.response?.data?.error);
    },
  });
}

export async function createTemplate(templateObject) {
  return await APIClient.post(
    '/template',
    { ...templateObject },
    {
      customErrorHandler: function (error) {
        alert('Error creating template:\n' + error?.response?.data?.error);
      },
      timeout: 0,
    }
  );
}

export async function createTemplateBlocks(googleSheetUrl, googleSlidesUrl, orientation) {
  return await APIClient.post(
    '/template/template_block',
    {
      google_sheets_url: googleSheetUrl,
      google_slides_url: googleSlidesUrl,
      orientation: orientation,
    },
    {
      customErrorHandler: function (error) {
        alert('Error creating template blocks:\n' + error?.response?.data?.error);
      },
      timeout: 0,
    }
  );
}

export async function getTemplateBlocks(orientation, returnUnpublished) {
  let queryParametersString = '';
  if (orientation) {
    queryParametersString = '?orientation=' + orientation;
  }
  if (returnUnpublished) {
    queryParametersString += `${queryParametersString ? '&' : '?'}return_unpublished=${returnUnpublished}`;
  }
  console.log(queryParametersString);
  return await APIClient.get('/template/template_block' + queryParametersString, {
    customErrorHandler: function (error) {
      alert('Error getting template blocks:\n' + error?.response?.data?.error);
    },
  });
}

export async function deleteTemplateBlock(id) {
  return await APIClient.delete(`/template/template_block/${id}`, {
    customErrorHandler: function (error) {
      alert('Error deleting template block:\n' + error?.response?.data?.error);
    },
  });
}

export async function updateTemplateBlock(templateObject) {
  return await APIClient.put(
    `/template/template_block/${templateObject.id}`,
    { ...templateObject },
    {
      customErrorHandler: function (error) {
        alert('Error updating template block:\n' + error?.response?.data?.error);
      },
    }
  );
}

export async function updateTemplate(templateObject) {
  return await APIClient.put(
    `/template/${templateObject.id}`,
    { ...templateObject },
    {
      customErrorHandler: function (error) {
        alert('Error updating template:\n' + error?.response?.data?.error);
      },
    }
  );
}

export async function getStandardInfo(standardId) {
  return await APIClient.get(`/template/standards/${standardId}`, {
    customErrorHandler: function (error) {
      alert('Error getting standard:\n' + error?.response?.data?.error);
    },
  });
}

export async function addToFavorites(templateId) {
  return await APIClient.post(
    `/template/${templateId}/favorite`,
    {},
    {
      customErrorHandler: function (error) {
        alert('Error adding template to favorites:\n' + error?.response?.data?.error);
      },
    }
  );
}

export async function removeFromFavorites(templateId) {
  return await APIClient.delete(`/template/${templateId}/favorite`, {
    customErrorHandler: function (error) {
      alert('Error removing template from favorites:\n' + error?.response?.data?.error);
    },
  });
}

export async function getSuggestedTemplates() {
  return await APIClient.get(`/template/suggested`);
}

export async function getActivities({
  all,
  category,
  page = 1,
  page_size = 10,
  search,
  template_formats,
}) {
  let queryParametersString = '';
  if (all) {
    queryParametersString = '?all=true';
  }
  if (category) {
    queryParametersString += `${queryParametersString ? '&' : '?'}category=${category}`;
  }
  if (page) {
    queryParametersString += `${queryParametersString ? '&' : '?'}page=${page}`;
  }
  if (page_size) {
    queryParametersString += `${queryParametersString ? '&' : '?'}page_size=${page_size}`;
  }
  if (search) {
    queryParametersString += `${queryParametersString ? '&' : '?'}search=${search}`;
  }
  if (template_formats) {
    queryParametersString += `${queryParametersString ? '&' : '?'}template_formats=${template_formats}`;
  }
  return await APIClient.get('/activities' + queryParametersString, {
    customErrorHandler: function (error) {
      alert('Error getting activities:\n' + error?.response?.data?.error);
    },
  });
}
