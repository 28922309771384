import mixpanel from 'mixpanel-browser';
import { isProd } from '../utils/environment';
import { clerk } from '../main';

const standardProps = () => {
  let props = {};
  if (window) {
    props.viewportWidth = window.innerWidth;
    props.viewportHeight = window.innerHeight;
  }
  return props;
};

// Function to get Clerk token
async function getClerkToken() {
  await clerk.load();
  if (!clerk.session) return null;
  return clerk.session.getToken({ template: 'userinfo' });
}

// Initialize Mixpanel with dynamic header setup
async function initMixpanel() {
  const token = await getClerkToken();
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        'LD-Session': localStorage.getItem('diffitSessionId'),
      }
    : { 'LD-Session': localStorage.getItem('diffitSessionId') };
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    api_host: import.meta.env.VITE_API_BASE_URL + '/mixpanel',
    xhr_headers: headers,
  });
}

// Wrap Mixpanel functions to ensure headers are updated on each call
let env_check = true;

let actions = {
  identify: async (id) => {
    if (env_check) {
      await initMixpanel();
      mixpanel.identify(id);
    }
  },
  alias: async (id) => {
    if (env_check) {
      await initMixpanel();
      mixpanel.alias(id);
    }
  },
  track: async (name, props) => {
    if (env_check) {
      await initMixpanel();
      const propsToSend = {
        ...props,
        ...standardProps(),
      };
      mixpanel.track(name, propsToSend);
      if (!isProd && name !== 'Page Viewed') {
        console.log(
          '[Mixpanel Event]\n\n',
          `${name}`,
          Object.keys(propsToSend || {}).length ? { ...propsToSend } : ''
        );
      }
    }
  },
  getDistinctId: async () => {
    // This is for anonymous user tracking
    if (env_check) {
      await initMixpanel();
      return mixpanel.get_distinct_id();
    }
  },
  people: {
    set: async (props) => {
      if (env_check) {
        await initMixpanel();
        mixpanel.people.set(props);
      }
    },
  },
};

export let Mixpanel = actions;
