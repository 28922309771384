import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSignIn, useSignUp } from '@clerk/clerk-react';
import ModalX from './ModalX';
import { Mixpanel } from '../services/Mixpanel';
import { useSessionStorage } from '../services/useSessionStorage';
import WebFont from 'webfontloader';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import { ArrowLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import Image from './Image';

const SIGN_IN_STATE = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  VERIFY_CODE: 'VERIFY_CODE',
  FORGOT_PASSWORD_ENTER_EMAIL: 'FORGOT_PASSWORD_ENTER_EMAIL',
  FORGOT_PASSWORD_ENTER_CODE: 'FORGOT_PASSWORD_ENTER_CODE',
};

export default function SignInModal({ open, setOpen, startInSignUp, premiumSignIn = false }) {
  const { signIn } = useSignIn();
  const { isLoaded, signUp, setActive } = useSignUp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectUrl, setRedirectUrl] = useSessionStorage('redirect_url_complete', { url: '' });
  const [signInState, setSignInState] = useState(SIGN_IN_STATE.SIGN_IN);
  const [sharerId, setSharerId] = useState('');
  const [code, setCode] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const signInWith = (strategy) => {
    // Include success=True alongside the existing params
    let existing = window.location.search;
    // Remove the leading question mark
    if (existing.charAt(0) === '?') {
      existing = existing.substring(1);
      existing = '&' + existing;
    }
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: window.location.origin + '/auth',
      redirectUrlComplete:
        window.location.pathname + '?success=true' + existing + window.location.hash,
    });
  };

  useEffect(() => {
    setErrorMessage('');
  }, [open, signInState]);

  useEffect(() => {
    // Reset form
    if (open) {
      setErrorMessage('');
      setSignInState(startInSignUp ? SIGN_IN_STATE.SIGN_UP : SIGN_IN_STATE.SIGN_IN);
    }
  }, [open]);

  useEffect(() => {
    let edited = false;
    if (searchParams.get('to_email')) {
      setEmailAddress(searchParams.get('to_email'));
      searchParams.delete('to_email');
      edited = true;
    }
    if (searchParams.get('sharer_id')) {
      setSharerId(searchParams.get('sharer_id'));
      searchParams.delete('sharer_id');
      edited = true;
    }
    if (edited) {
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const verifyCode = async (e) => {
    e.preventDefault();
    if (!isLoaded || isLoading) {
      return;
    }
    Mixpanel.track('Email/password verify code', { sharerId: sharerId });

    setIsLoading(true);
    try {
      let completeSignUp;
      if (signInState === SIGN_IN_STATE.SIGN_IN) {
        completeSignUp = await signIn.attemptFirstFactor({
          strategy: 'email_code',
          code: code,
        });
      } else {
        completeSignUp = await signUp.attemptEmailAddressVerification({
          code,
        });
      }

      if (completeSignUp.status !== 'complete') {
        /*  investigate the response, to see if there was an error
         or if the user needs to complete more steps.*/
        console.log(JSON.stringify(completeSignUp, null, 2));
      }
      if (completeSignUp.status === 'complete') {
        await setActive({ session: completeSignUp.createdSessionId });
        searchParams.append('success', true);
        setSearchParams(searchParams);
        // Need a reload for the Clerk session to be active
        window.location.reload(false);
      }
    } catch (err) {
      setErrorMessage(err.errors[0].longMessage);
      console.error(JSON.stringify(err, null, 2));
    }
    setIsLoading(false);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!isLoaded || isLoading) {
      return;
    }
    Mixpanel.track('Start email/password forgot password');
    setSignInState(SIGN_IN_STATE.FORGOT_PASSWORD_ENTER_EMAIL);
  };

  const handleSignInOrUp = async (e) => {
    e.preventDefault();
    if (!isLoaded || isLoading) {
      return;
    }
    setIsLoading(true);
    setErrorMessage('');

    if (signInState === SIGN_IN_STATE.SIGN_IN) {
      await signUserIn();
    } else {
      await signUserUp();
    }
    setIsLoading(false);
  };

  const signUserIn = async () => {
    Mixpanel.track('Email/password sign in', { sharerId: sharerId });
    try {
      const result = await signIn.create({
        identifier: emailAddress,
      });

      if (result.status === 'needs_first_factor') {
        // Check to see if one of the supportedFirstFactors is password. Require that.
        const hasPassword = result.supportedFirstFactors.find(
          (factor) => factor.strategy === 'password'
        );
        if (hasPassword) {
          const passwordResponse = await result.attemptFirstFactor({
            strategy: 'password',
            password: password,
          });
          if (passwordResponse.status !== 'complete') {
            setErrorMessage('Incorrect password');
            return;
          } else {
            await setActive({ session: result.createdSessionId });
            searchParams.append('success', true);
            setSearchParams(searchParams);
            // Need a reload for the Clerk session to be active
            window.location.reload();
          }
        }

        // Otherwise, they have not set a password yet. Tell them to log in via Google.
        else {
          setErrorMessage('Please sign in with Google');
          return;
        }
      } else {
        /*Investigate why the login hasn't completed */
        console.log(result);
        console.error(JSON.stringify(err, null, 2));
      }
    } catch (err) {
      if (errorMessage === "Couldn't find your account.") {
        setErrorMessage('No account found with that email address.');
      } else {
        setErrorMessage(err?.errors[0]?.message);
        console.error(JSON.stringify(err, null, 2));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const signUserUp = async () => {
    Mixpanel.track('Email/password begin sign up', { sharerId: sharerId });

    try {
      const result = await signUp.create({
        emailAddress: emailAddress,
        password: password,
        firstName: firstName,
        lastName: lastName,
      });

      // send the email.
      await result.prepareEmailAddressVerification({ strategy: 'email_code' });

      // change the UI to our pending section.
      setSignInState(SIGN_IN_STATE.VERIFY_CODE);
    } catch (err) {
      setErrorMessage(err.errors[0].longMessage);
      console.error(JSON.stringify(err, null, 2));
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto'],
      },
    });
  }, []);

  function handleGoogleClick() {
    Mixpanel.track('Continue with Google', { sharerId: sharerId });
    signInWith('oauth_google');
    let existing = window.location.search;
    // Remove the leading question mark
    if (existing.charAt(0) === '?') {
      existing = existing.substring(1);
      existing = '&' + existing;
    }
    setRedirectUrl({
      url: window.location.pathname + '?success=true' + existing + window.location.hash,
    });
  }

  function handleMicrosoftClick() {
    Mixpanel.track('Continue with Microsoft', { sharerId: sharerId });
    try {
      signInWith('oauth_microsoft');
    } catch (err) {
      console.log(err);
    }
    let existing = window.location.search;
    // Remove the leading question mark
    if (existing.charAt(0) === '?') {
      existing = existing.substring(1);
      existing = '&' + existing;
    }
    setRedirectUrl({
      url: window.location.pathname + '?success=true' + existing + window.location.hash,
    });
  }

  function handleClose() {
    Mixpanel.track('Close login modal');
    setOpen(false);
  }

  const forgotPasswordSubmitEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      await signIn.create({
        strategy: 'reset_password_email_code',
        identifier: emailAddress,
      });
      setSignInState(SIGN_IN_STATE.FORGOT_PASSWORD_ENTER_CODE);
    } catch (err) {
      console.error('error', err.errors[0].longMessage);
      if (
        err.errors[0].longMessage ===
        "`reset_password_email_code` isn't allowed for `strategy` when user's password is not set."
      ) {
        setErrorMessage('Please log in with Google');
      } else {
        setErrorMessage(err.errors[0].longMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPasswordSubmitCodeAndNewPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const result = await signIn.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code: code,
        password: password,
      });
      if (result.status === 'complete') {
        await setActive({ session: result.createdSessionId });
        searchParams.append('success', true);
        setSearchParams(searchParams);
        // Need a reload for the Clerk session to be active
        window.location.reload(false);
      }
    } catch (err) {
      console.error('error', err.errors[0].longMessage);
      setErrorMessage(err.errors[0].longMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={handleClose} unmount={false}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                <div>
                  <ModalX setOpen={handleClose} />
                  {[
                    SIGN_IN_STATE.FORGOT_PASSWORD_ENTER_CODE,
                    SIGN_IN_STATE.FORGOT_PASSWORD_ENTER_EMAIL,
                    SIGN_IN_STATE.VERIFY_CODE,
                  ].includes(signInState) && (
                    <div className='absolute left-0 top-0 pl-4 pt-4'>
                      <button
                        type='button'
                        autoFocus={false}
                        className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'
                        onClick={() => {
                          if (signInState === SIGN_IN_STATE.VERIFY_CODE) {
                            setSignInState(SIGN_IN_STATE.SIGN_UP);
                          } else {
                            setSignInState(SIGN_IN_STATE.SIGN_IN);
                          }
                        }}>
                        <span autoFocus={false} className='sr-only'>
                          Back
                        </span>
                        <ArrowLeftIcon autoFocus={false} className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  )}
                  <div className='mx-auto flex h-20 w-auto items-center justify-center'>
                    <Image className='h-20 w-auto' src='/logo.svg' aria-label='Diffit Logo' />
                  </div>
                  {[SIGN_IN_STATE.SIGN_IN, SIGN_IN_STATE.SIGN_UP].includes(signInState) && (
                    <>
                      {' '}
                      {!showMoreOptions && (
                        <div className='mt-3 text-center sm:mt-5'>
                          {!premiumSignIn && (
                            <h3 className='text-lg leading-6 text-gray-900'>
                              Click below to create your free account!
                            </h3>
                          )}
                          {premiumSignIn && (
                            <h3 className='text-lg leading-6 text-gray-900'>
                              Create an account to join your school's premium subscription!
                            </h3>
                          )}
                          <div className='mt-2 text-md text-gray-500 mb-8'>
                            If you already have one, we'll log you in.
                          </div>
                        </div>
                      )}
                      <div className='flex flex-col items-center mt-5'>
                        <button
                          type='button'
                          onClick={handleGoogleClick}
                          className='relative w-full inline-flex items-center justify-center bg-white rounded-full px-4 py-3 text-md font-semibold text-gray-900 shadow-md hover:shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
                          <Image src='/google-logo.png' className='absolute left-4 h-6 w-auto' />
                          <span className='font-roboto pl-6 pr-3'>Sign in with Google</span>
                        </button>
                      </div>
                      {showMoreOptions && (
                        <>
                          <div className='flex flex-col items-center mt-2'>
                            <button
                              type='button'
                              onClick={handleMicrosoftClick}
                              className='relative w-full inline-flex items-center justify-center bg-white rounded-full px-4 py-3 text-md font-semibold text-gray-900 shadow-md hover:shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
                              <Image
                                src='/microsoft-logo.png'
                                className='absolute left-4 h-6 w-auto'
                              />
                              <span className='font-roboto pl-6 pr-3'>Sign in with Microsoft</span>
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {signInState === SIGN_IN_STATE.FORGOT_PASSWORD_ENTER_EMAIL && (
                    <div>
                      <div className='mt-3 text-center text-m font-medium text-gray-900'>
                        Forgot your password? We can help.
                      </div>
                      <div className='mt-2 text-center text-sm font-medium text-gray-500'>
                        Enter your email address below and we'll send you a code to reset your
                        password.
                      </div>
                      <form onSubmit={forgotPasswordSubmitEmail}>
                        <label
                          htmlFor='email'
                          className='pt-3 block text-left w-full text-sm font-medium text-gray-700'>
                          Email address
                        </label>
                        <input
                          required
                          type='email'
                          id='email'
                          className='mt-1 w-full px-3 py-3 text-md text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500'
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                        />
                        {errorMessage && (
                          <div className='my-2 text-sm text-center text-red-500'>
                            {errorMessage}
                          </div>
                        )}
                        <button
                          disabled={isLoading}
                          type='submit'
                          className='mt-2 top-0 inline-flex w-full items-center justify-center gap-1 bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-3 rounded-md disabled:bg-emerald-500 disabled:text-gray-50'>
                          {isLoading && (
                            <div className='w-full flex py-1 justify-center'>
                              <LoadingSpinner />
                            </div>
                          )}
                          {!isLoading && <>Send Code</>}
                        </button>
                      </form>
                    </div>
                  )}
                  {signInState === SIGN_IN_STATE.FORGOT_PASSWORD_ENTER_CODE && (
                    <div>
                      <div className='mt-3 text-center text-m font-medium text-gray-900'>
                        Your code is on the way to your inbox.
                      </div>
                      <div className='mt-2 text-center text-sm font-medium text-gray-500'>
                        Enter the code from the email we sent you and your new password.
                      </div>
                      <form onSubmit={forgotPasswordSubmitCodeAndNewPassword}>
                        <label
                          htmlFor='code'
                          className='pt-3 block text-left w-full text-sm font-medium text-gray-700'>
                          Code
                        </label>
                        <input
                          required
                          type='string'
                          id='code'
                          className='mt-1 w-full px-3 py-3 text-md text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500'
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <label
                          htmlFor='password'
                          className='pt-3 block text-left w-full text-sm font-medium text-gray-700'>
                          New Password
                        </label>
                        <input
                          required
                          type='password'
                          id='password'
                          className='mt-1 w-full px-3 py-3 text-md text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500'
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {errorMessage && (
                          <div className='my-2 text-sm text-center text-red-500'>
                            {errorMessage}
                          </div>
                        )}
                        <button
                          disabled={isLoading}
                          type='submit'
                          className='mt-2 top-0 inline-flex w-full items-center justify-center gap-1 bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-3 rounded-md disabled:bg-emerald-500 disabled:text-gray-50'>
                          {isLoading && (
                            <div className='w-full flex py-1 justify-center'>
                              <LoadingSpinner />
                            </div>
                          )}
                          {!isLoading && <>Save New Password</>}
                        </button>
                      </form>
                    </div>
                  )}
                  {signInState === SIGN_IN_STATE.VERIFY_CODE && (
                    <div>
                      <div className='mt-3 text-center text-sm font-medium text-gray-900'>
                        Check your email for a verification code.
                      </div>
                      <input
                        type='text'
                        className='mt-1 w-full px-3 py-3 text-md text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500'
                        onChange={(e) => setCode(e.target.value)}
                      />
                      {errorMessage && (
                        <div className='my-2 text-sm text-center text-red-500'>{errorMessage}</div>
                      )}
                      <button
                        onClick={verifyCode}
                        className='mt-4 top-0 inline-flex w-full items-center justify-center gap-1 bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-3 rounded-md disabled:bg-emerald-500 disabled:text-gray-50'>
                        {isLoading && (
                          <div className='w-full flex py-1 justify-center'>
                            <LoadingSpinner />
                          </div>
                        )}
                        {!isLoading && <>Submit Code</>}
                      </button>
                    </div>
                  )}
                  {(signInState === SIGN_IN_STATE.SIGN_IN ||
                    signInState === SIGN_IN_STATE.SIGN_UP) && (
                    <>
                      {showMoreOptions && (
                        <div className='mt-5'>
                          <div className='flex w-full items-center text-gray-600 text-sm mb-5 gap-2'>
                            <div className='h-1 w-full border-b mx-2'></div>
                            <span className='min-w-fit font-semibold'>OR</span>
                            <div className='h-1 w-full border-b mx-2'></div>
                          </div>
                          <form onSubmit={handleSignInOrUp}>
                            {signInState === SIGN_IN_STATE.SIGN_UP && (
                              <div className='flex flex-row'>
                                <div className='basis-1/2 pr-1'>
                                  <label
                                    htmlFor='first'
                                    className='block text-left w-full text-sm font-medium text-gray-700'>
                                    First name
                                  </label>
                                  <input
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    type='text'
                                    id='first'
                                    className='mt-1 w-full px-3 py-3 text-md text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500'
                                  />
                                </div>
                                <div className='basis-1/2 pl-1'>
                                  <label
                                    htmlFor='last'
                                    className='block text-left w-full text-sm font-medium text-gray-700'>
                                    Last name
                                  </label>
                                  <input
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    type='text'
                                    id='last'
                                    className='mt-1 w-full px-3 py-3 text-md text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500'
                                  />
                                </div>
                              </div>
                            )}
                            <label
                              htmlFor='email'
                              className='mt-1 block text-left w-full text-sm font-medium text-gray-700'>
                              Email address
                            </label>
                            <input
                              onChange={(e) => setEmailAddress(e.target.value)}
                              value={emailAddress}
                              required
                              type='email'
                              id='email'
                              className='mt-1 w-full px-3 py-3 text-md text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500'
                            />
                            <label
                              htmlFor='password'
                              className='mt-1 block text-left w-full text-sm font-medium text-gray-700'>
                              Password
                            </label>
                            <input
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              type='password'
                              id='password'
                              className='mt-1 w-full px-3 py-3 text-md text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500'
                            />
                            {signInState === SIGN_IN_STATE.SIGN_IN && (
                              <p
                                className='mt-2 text-xs text-center w-full hover:text-gray-800 hover:cursor-pointer'
                                onClick={handleForgotPassword}
                                disabled={isLoading}>
                                Forgot your password?
                              </p>
                            )}
                            <div className='items-center flex-col mt-2'>
                              <div className='w-full'>
                                {errorMessage && (
                                  <div className='my-2 text-sm text-center text-red-500'>
                                    {errorMessage}
                                  </div>
                                )}
                                <button
                                  disabled={isLoading}
                                  type='submit'
                                  className='top-0 inline-flex w-full items-center justify-center gap-1 bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-3 rounded-md disabled:bg-emerald-500 disabled:text-gray-50'>
                                  {isLoading && (
                                    <div className='w-full flex py-1 justify-center'>
                                      <LoadingSpinner />
                                    </div>
                                  )}
                                  {signInState === SIGN_IN_STATE.SIGN_IN && !isLoading && (
                                    <>Sign in</>
                                  )}
                                  {signInState === SIGN_IN_STATE.SIGN_UP && !isLoading && (
                                    <>Create an account</>
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                          <button
                            className='my-2 text-sm text-center w-full hover:text-gray-800'
                            onClick={() =>
                              setSignInState(
                                signInState === SIGN_IN_STATE.SIGN_UP
                                  ? SIGN_IN_STATE.SIGN_IN
                                  : SIGN_IN_STATE.SIGN_UP
                              )
                            }>
                            {signInState === SIGN_IN_STATE.SIGN_IN && (
                              <>New to Diffit? Create an Account</>
                            )}
                            {signInState === SIGN_IN_STATE.SIGN_UP && <>Have an account? Sign in</>}
                          </button>
                        </div>
                      )}
                      <div className='inline-flex items-center justify-center w-full mt-4'>
                        <button
                          onClick={() => setShowMoreOptions(!showMoreOptions)}
                          className='inline-flex items-center gap-1 text-gray-500 hover:text-gray-600 text-sm'>
                          <span>{`${showMoreOptions ? 'Fewer' : 'More'} sign-in options`}</span>
                          {showMoreOptions ? (
                            <ChevronUpIcon className='h-4 w-auto' />
                          ) : (
                            <ChevronDownIcon className='h-4 w-auto' />
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
