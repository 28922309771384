import { useContext, useEffect, useState } from 'react';
import { getNotices } from '../services/NoticeService';
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import PremiumIcon from './PremiumIcon';
import { Mixpanel } from '../services/Mixpanel';
import { useUser } from '@clerk/clerk-react';
import { getTrialEndDate, isFreeTrialUser, isInPremiumOrg } from '../utils/isPremiumUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Markdown from 'react-markdown';
import { DiffitUserContext } from '../contexts/DiffitUserContext';
import NotificationDialog from './NotificationDialog';
import { BoltIcon, CheckIcon, HeartIcon } from '@heroicons/react/24/solid';

const getColor = (type) => {
  switch (type) {
    case 'success':
      return 'bg-emerald-50 text-[#4acaa8]';
    case 'premium':
      return 'bg-purple-50 text-purple-400';
    default:
      return 'bg-emerald-100 text-emerald-800';
  }
};

const getIcon = (type) => {
  switch (type) {
    case 'success':
      return <HeartIcon className='h-8 w-auto' />;
    case 'premium':
      return (
        <div className='flex items-center w-auto' aria-hidden='true'>
          <PremiumIcon height='h-6' />
        </div>
      );
    default:
      return 'bg-emerald-100 text-emerald-800';
  }
};

export default function Notices() {
  const { user, isLoaded, isSignedIn } = useUser();
  const { diffitUser } = useContext(DiffitUserContext);
  const { enablePaywallFeaturesForPremiumUsers } = useFlags();
  const [noticeMessages, setNoticeMessages] = useState([]);
  const [isVisible, setIsVisible] = useState(new Set([]));

  const getIsVisibile = (visibility) => {
    switch (visibility) {
      case 'signed_in_only':
        return !!isSignedIn;
      case 'signed_in_premium_org_only':
        return !!(isSignedIn && isInPremiumOrg(diffitUser));
      case 'signed_in_free_trial_only':
        return !!(isSignedIn && isFreeTrialUser(user, diffitUser) && !isInPremiumOrg(diffitUser));
      case 'signed_out_only':
        return !!(isLoaded && !isSignedIn);
      case 'all_users':
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const notice = await getNotices();
        setNoticeMessages(notice.data.alerts);
      } catch {
        setIsVisible([]);
      }
    };
    fetchNotices();
  }, []);

  useEffect(() => {
    const notices = JSON.parse(localStorage.getItem('notices'));
    var newIsVisible = new Set([...isVisible]);

    noticeMessages.map((noticeMessage) => {
      if (noticeMessage?.id) {
        if (
          (notices &&
            notices.some((notice) => notice.id === noticeMessage.id && notice.acknowledged)) ||
          !getIsVisibile(noticeMessage.visibility)
        ) {
          newIsVisible = new Set([...newIsVisible].filter((item) => item != noticeMessage.id));
        } else {
          newIsVisible = new Set([...newIsVisible, noticeMessage.id]);
        }
      }
    });

    setIsVisible(newIsVisible);
  }, [noticeMessages, user, diffitUser]);

  const acknowledgeNotice = (noticeMessage) => {
    const localNotices = JSON.parse(localStorage.getItem('notices'));
    const notices = localNotices || [];
    var newIsVisible = new Set([...isVisible].filter((item) => item != noticeMessage.id));
    setIsVisible(newIsVisible);
    Mixpanel.track('Acknowledge Notice', { message: noticeMessage.message });
    // set local storage value for notice with current id and set acknowledged to true
    localStorage.setItem(
      'notices',
      JSON.stringify([...notices, { id: noticeMessage.id, acknowledged: true }])
    );
  };

  return (
    <>
      {noticeMessages.map(
        (noticeMessage) =>
          (isVisible || []).has(noticeMessage.id) &&
          noticeMessage.is_published &&
          (noticeMessage.type === 'premium'
            ? enablePaywallFeaturesForPremiumUsers &&
              !!user &&
              isFreeTrialUser(user, diffitUser) &&
              !isInPremiumOrg(diffitUser)
            : true) && (
            <Notice
              key={noticeMessage.id}
              id={noticeMessage.id}
              type={noticeMessage.type}
              title={noticeMessage.title}
              message={noticeMessage.message}
              callToAction={noticeMessage.call_to_action}
              acknowledgeNotice={acknowledgeNotice}
            />
          )
      )}
    </>
  );
}

export function Notice(props) {
  const [showNotice, setShowNotice] = useState(true);

  const dismiss = () => {
    setShowNotice(false);
    props.acknowledgeNotice(props);
  };

  return (
    <NotificationDialog
      open={showNotice}
      handleConfirm={dismiss}
      title={props.title}
      message={props.message}
      confirmText={props.callToAction || 'Dismiss'}
      icon={getIcon(props.type)}
      color={getColor(props.type)}
    />
  );
}
