import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ModalX from './ModalX';
import { Mixpanel } from '../services/Mixpanel';
import PremiumIcon from './PremiumIcon';
import Image from './Image';

export default function WelcomeToPremiumModal({ open, setOpen, schoolName }) {
  const returnButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-50' initialFocus={returnButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <ModalX setOpen={setOpen} />
                <div>
                  <div className='mx-auto flex h-16 w-auto items-center justify-center'>
                    <Image className='h-16 w-auto pr-3' src='/logo.svg' aria-label='Diffit Logo' />
                    <PremiumIcon height='h-8' />
                  </div>
                  <div className=''>
                    <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900'>
                      Congratulations!
                    </Dialog.Title>
                    <div className='mt-3'>
                      <div className='text-sm sm:text-lg text-gray-600'>
                        <span className='mb-1.5'>
                          You now have access to premium features
                          {schoolName ? (
                            <span>
                              {' '}
                              under <b>{schoolName}'s</b> license!
                            </span>
                          ) : (
                            `!`
                          )}
                        </span>
                        <p className='mb-1 mt-3'>Teachers use Diffit to:</p>
                        <ul className='list-disc ml-4 leading-normal'>
                          <li>Generate grade-level appropriate resources</li>
                          <li>Adapt existing materials for any reader</li>
                          <li>Export resources to student-ready activity templates</li>
                        </ul>
                        <div className='aspect-w-16 aspect-h-9 overflow-hidden'>
                          <Image src='/sign-in-success.png' className='object-contain' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type='button'
                  className='inline-flex w-full justify-center rounded-md bg-emerald-600 mt-1 px-3 py-2 text-md font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 sm:col-start-2'
                  onClick={() => {
                    setOpen(false);
                    Mixpanel.track('Premium Modal: Get Started');
                  }}
                  ref={returnButtonRef}>
                  Get Started!
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
