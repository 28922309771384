import CreateAxiosInstance from './axiosConfig';
const APIClient = CreateAxiosInstance(import.meta.env.VITE_API_BASE_URL);

export async function getNotices() {
  return await APIClient.get(`/admin/notice`);
}

export async function updateNotice(notice) {
  return await APIClient.put(`/admin/notice`, notice);
}

export async function createNotice(notice) {
  return await APIClient.post(`/admin/notice`, notice);
}

export async function deleteNotice(id) {
  return await APIClient.delete(`/admin/notice`, { data: { id: id } });
}
