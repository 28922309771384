import CreateAxiosInstance from './axiosConfig';
const APIClient = CreateAxiosInstance(import.meta.env.VITE_API_BASE_URL);

export const defaultErrorHandler = function (error) {
  let serverMessage = error?.response?.data?.error;
  let userMessage = !!serverMessage
    ? serverMessage
    : `There was an error processing your request. Please try again later.\n\n${error.message}`;
  alert(userMessage);
};

export async function createPacket(form, customErrorHandler) {
  return await APIClient.post('/packet', form, {
    headers: {
      'Content-Type': 'application/json',
    },
    customErrorHandler: customErrorHandler || defaultErrorHandler,
  });
}

export async function relevelPacket(packetInfo) {
  return await APIClient.post('/packet', packetInfo, {
    headers: {
      'Content-Type': 'application/json',
    },
    customErrorHandler: 'There was an error releveling your resource. Please try again later.',
  });
}

export async function getPacket(packetId) {
  return await APIClient.get('/packet/' + packetId, {
    customErrorHandler: 'There was an error getting your resource. Please try again later.',
  });
}

export async function createPacketFeedback(packetId, feedback = { score: 0, comment: '' }) {
  return await APIClient.post(`/packet/${packetId}/feedback`, feedback, {
    customErrorHandler: 'There was an error getting your resource. Please try again later.',
  });
}

export async function createPacketResourceFeedback(
  packetId,
  feedback = { value: 'Thumbs Up', resource_type: 'Some Resource' }
) {
  return await APIClient.post(`/packet/${packetId}/resource_feedback`, feedback, {
    customErrorHandler: 'There was an error getting your resource. Please try again later.',
  });
}

export async function getPackets(search, page, pageSize) {
  return await APIClient.get(
    '/packet?search=' + search + '&page=' + page + '&page_size=' + pageSize,
    {
      customErrorHandler: 'There was an error getting your resources. Please try again later.',
    }
  );
}

export async function deletePacket(packetId) {
  return await APIClient.delete('/packet/' + packetId, {
    customErrorHandler: 'There was an error deleting your resource. Please try again later.',
  });
}

export async function createResource(
  packetId,
  resourceType,
  replace,
  current,
  readingLevelId,
  language,
  options,
  jsonifyExistingOnly,
  userInstructions,
  skills,
  onErrorFn
) {
  const questionType = options?.questionType?.name;
  const number = options?.number.name;
  const vocabList = options?.vocabList;

  return await APIClient.patch(
    '/packet/' +
      packetId +
      '?resource_type=' +
      resourceType +
      '&replace=' +
      replace +
      '&reading_level_id=' +
      readingLevelId +
      '&language=' +
      language +
      '&vocab_list=' +
      vocabList +
      '&question_type=' +
      questionType +
      '&jsonify_existing_only=' +
      jsonifyExistingOnly +
      '&user_instructions=' +
      userInstructions +
      '&number=' +
      number,
    {
      current: current,
      skills: skills,
    },
    {
      customErrorHandler: function (error) {
        if (error.response?.status !== 403) {
          if (onErrorFn) {
            onErrorFn(error);
          } else if (error.response?.data?.error?.includes('unable to process the image')) {
            alert("The selected image is giving us some trouble - sorry about that! Please try a different image.");
          } else {
            alert('There was an error adding the resource. Please try again later.');
          }
        }
      },
    }
  );
}

export async function adjustAdaptationLength(packetId, readingLevelId, language, requestedLength) {
  return await APIClient.patch(
    '/packet/' +
      packetId +
      '?adjust_adaptation_length=' +
      requestedLength +
      '&reading_level_id=' +
      readingLevelId +
      '&language=' +
      language,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
      customErrorHandler:
        'There was an error adjusting the adaptation length. Please try again later.',
    }
  );
}

export async function saveSharedPacket(packet) {
  let packetCopy = { ...packet };
  delete packetCopy.id;
  return await APIClient.post(
    '/packet?clone=true',
    {
      packet: packetCopy,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      customErrorHandler: 'There was an error saving the shared resource. Please try again later.',
    }
  );
}

export async function createTranslatedPacket(packet, language, resourceTypes) {
  let packetCopy = { ...packet };
  return await APIClient.post(
    '/packet?translate=true',
    {
      packet: packetCopy,
      resources: resourceTypes,
      language: language,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      customErrorHandler: function (error) {
        let serverMessage = error?.response?.data?.error;
        let userMessage = !!serverMessage
          ? serverMessage
          : `There was an error processing your request. Please try again later.\n\n${error.message}`;
        alert(userMessage);
      },
    }
  );
}

export async function updatePacketSkills(packetId, skills) {
  return await APIClient.patch(
    '/packet/' + packetId + '/skills',
    {
      skills: skills,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      customErrorHandler: function (error) {
        let serverMessage = error?.response?.data?.error;
        let userMessage = !!serverMessage
          ? serverMessage
          : `There was an error processing your request. Please try again later.\n\n${error.message}`;
        alert(userMessage);
      },
    }
  );
}

export async function updatedPacketWithAllowedCitations(packet, allowedCitations) {
  return await APIClient.post(
    '/packet?regenerate=true',
    {
      packet: { ...packet },
      allowedCitations: allowedCitations,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      customErrorHandler: function (error) {
        let serverMessage = error?.response?.data?.error;
        let userMessage = !!serverMessage
          ? serverMessage
          : `There was an error processing your request. Please try again later.\n\n${error.message}`;
        alert(userMessage);
      },
    }
  );
}

export function getLanguageStandardsFromPacket(packet) {
  let languageStandards = new Set();
  let generatedContent = [];
  if (packet.questions_json) {
    let resource = JSON.parse(packet.questions_json);
    const { questions } = resource;
    // Go through each question. If there is a skill_json object underneath it, add its id as the key to the set and set its value to the skill_json
    questions.forEach((question) => {
      if (question.skill_json) {
        const { id } = question.skill_json;
        generatedContent.push(question);
        languageStandards.add(id);
      }
    });
  }

  if (packet.short_json) {
    let resource = JSON.parse(packet.short_json);
    const { prompts } = resource;
    prompts.forEach((prompt) => {
      if (prompt.skill_json) {
        const { id } = prompt.skill_json;
        generatedContent.push(prompt);
        languageStandards.add(id);
      }
    });
  }

  let output = Array.from(languageStandards)
    .map((standard) => {
      const standardObj = generatedContent.find((content) => content.skill_json.id === standard);
      return standardObj.skill_json;
    })
    .filter((standard) => standard.framework !== 'Depth of Knowledge');

  // Return the set as an array
  return output;
}

export async function validatePacket({ topic, reading_level }) {
  const response = await APIClient.post(
    '/packet/validate',
    {
      topic,
      reading_level,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
}
