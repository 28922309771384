// Generic image component
// Will properly update path if we want to host from CDN instead of S3

export const getSrc = (src) => {
  if (!src) {
    return '';
  }
  if (src.includes('diffit-pdf-upload-dev.s3.amazonaws.com')) {
    return src.replace('diffit-pdf-upload-dev.s3.amazonaws.com', 'images-dev.diffit.me');
  } else if (src.includes('diffit-pdf-upload-prod.s3.amazonaws.com')) {
    return src.replace('diffit-pdf-upload-prod.s3.amazonaws.com', 'images.diffit.me');
  } else {
    return src;
  }
};

export default function Image({ src, ...props }) {
  return <img src={getSrc(src)} {...props} loading="lazy" />;
}
