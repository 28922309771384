import { Popover, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

const getClassesForVariant = (variant) => {
  switch (variant) {
    case 'primary':
      return 'bg-gray-100 rounded-full';
    case 'secondary':
      return 'bg-white border border-gray-400 bg-white cursor-pointer text-gray-900 py-1.5 px-3';
    case 'tertiary':
      return 'bg-gray-100 text-gray-700';
    default:
      return 'bg-blue-400 text-gray-700';
  }
};

export const Select = ({
  variant = 'primary', // primary, secondary, tertiary
  options, // options for the select
  selected, // array or single option. array will allow multiple selections
  setselected, // function to set
  buttonText, // what the button should say when closed
  disabled,
  allValue,
}) => {
  const multi = Array.isArray(selected);
  const renderOptions = (option) => (
    <div className="relative flex items-start pb-2 last:pb-0" key={option}>
      <div className="text-gray-700 w-full flex text-left text-sm items-center">
        {multi ? (
          <div className="px-4 py-2 pt-3">
            <input
              type="checkbox"
              id={option}
              name={option}
              checked={selected?.includes(option)}
              onChange={(event) => {
                if (event.target.checked) {
                  if (option === allValue) {
                    if (selected.length === options.length - 1) {
                      return setselected([]);
                    }
                    return setselected(options.filter((el) => el !== allValue));
                  }
                  if (selected) {
                    return setselected([...selected, option]);
                  } else {
                    return setselected([option]);
                  }
                } else {
                  return setselected(selected.filter((el) => el !== option));
                }
              }}
              className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
            />
            <label htmlFor={option} className="px-2">
              {option}
            </label>
          </div>
        ) : (
          <button
            id={option}
            onClick={() => {
              if (selected === option) {
                return setselected(null);
              }
              return setselected(option);
            }}
            className={`w-full flex justify-between items-center text-left border-gray-300 focus:ring-emerald-600 py-1.5 px-3 hover:bg-emerald-600 hover:text-white text-gray-700 h-10 ${option === selected ? 'font-semibold' : ''}`}>
            {option}
            <CheckIcon className={`h-4 w-4 ml-2 ${option === selected ? '' : 'invisible'}`} aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );

  return (
    <Popover as="div" className="relative inline-block text-left print:hidden">
      <div>
        <Popover.Button
          disabled={disabled}
          className={`inline-flex w-full items-center justify-center gap-x-0.5 rounded text-sm text-gray-400 disabled:text-gray-600 whitespace-nowrap disabled:hover:bg-white hover:bg-gray-100 focus:outline-1 focus:outline-emerald-600 group ${getClassesForVariant(
            variant
          )} py-1.5 px-3 h-10`}>
          {buttonText}
          <ChevronDownIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
        </Popover.Button>
      </div>
      <Transition
        as={'div'}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Popover.Panel className="divide-y divide-gray-100 absolute right-0 z-10 mt-1 min-w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto max-h-72">
          <div>
            <div>
              {options.map((item) => {
                if (typeof item === 'string') {
                  return renderOptions(item);
                } else {
                  return (
                    <div key={item.title}>
                      {item.options.map((option) => renderOptions(option))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

