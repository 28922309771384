export const BasicsIcon = ({ className = '', }) => {
  return (
    <div className={className}>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 24 24'

        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19 12.5H22L12 3.5L2 12.5H5V15.5H3V17.5H5V20.5H7V17.5H11V20.5H13V17.5H17V20.5H19V17.5H21V15.5H19V12.5ZM7 15.5V10.69L11 7.09V15.5H7ZM13 15.5V7.09L17 10.69V15.5H13Z'
        />
      </svg>
    </div>
  );
};
