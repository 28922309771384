const SHAPES = {
  circle: 'circle',
  rectangle: 'rectangle',
};
export const PARTNER_COLLECTION_CATEGORIES = {
  'Ditch That Textbook': {
    website: 'ditchthattextbook.com',
    websiteName: 'Ditch That Textbook',
    image: 'https://images.diffit.me/bff4d358-aa53-459a-8bf8-e122190b34db.jpg',
    shape: SHAPES.rectangle,
  },
  'Too Cool for Middle School': {
    author: 'Megan Forbes',
    website: 'teacherspayteachers.com/store/too-cool-for-middle-school',
    websiteName: 'Too Cool For Middle School',
    image: 'https://images.diffit.me/e1b6ab78-0670-48ff-9714-3559b105cc42.jpg',
    shape: SHAPES.circle,
  },
  'Hello Teacher Lady': {
    author: 'Shana Ramin',
    website: 'helloteacherlady.com',
    websiteName: 'Hello Teacher Lady',
    image:
      'https://images.squarespace-cdn.com/content/v1/5b02186afcf7fdd9101774c4/c6418fb3-9414-4b2b-ba87-d6d5ec1ea67e/CirclePhotoTransparent.png?format=1000w%201000w',
    shape: SHAPES.circle,
  },
  EduProtocols: {
    website: 'eduprotocolsplus.com/',
    websiteName: 'EduProtocols',
    image: 'https://images.diffit.me/dc6c066e-a904-44f6-b9ed-b4b7b23dfa66.jpg',
    shape: SHAPES.rectangle,
  },
};
