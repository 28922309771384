import { format, parseISO } from 'date-fns';

const ONE_DAY = 86400000;

const formatDate = (unformattedDate) => format(unformattedDate, 'MMMM do, yyyy');

export const getTrialEndDate = (user, diffitUser, addDays = 0) => {
  if (!user) return null;
  if (diffitUser) {
    if (addDays) {
      return formatDate(
        new Date(parseISO(diffitUser.trial_end_date_iso).getTime() + addDays * ONE_DAY)
      );
    }
    return formatDate(parseISO(diffitUser.trial_end_date_iso));
  }

  const now = Date.now();

  // There's a small gap between creating the user in Clerk and then updating on the backend, so give the benefit of the doubt
  // Set end date to 30 days from today
  if (user.createdAt > now - ONE_DAY) {
    const endDate = formatDate(new Date(now + 60 * ONE_DAY));
    return endDate;
  }
  return null;
};

export const setExtendWithSchoolOpen = (searchParams, setSearchParams) => {
  searchParams.set('extend_with_school', true);
  setSearchParams(searchParams);
};

export const userHasEnteredSchoolRecord = (diffitUser) => {
  if (!diffitUser) {
    return false;
  }
  return diffitUser.has_school_record;
};

export const userHasCompletedTrialExtensionSurvey = (diffitUser) => {
  if (!diffitUser) {
    return false;
  }
  let seenNux = diffitUser.seen_nux_json ? JSON.parse(diffitUser.seen_nux_json) : {};
  return !!seenNux['submitted_trial_extension_survey'];
};

export const userCanExportActivity = ({ activity, isPremiumUser }) => {
  if (!activity) {
    return true;
  }
  return isPremiumUser || activity?.is_premium === false || activity?.is_free_this_month === true;
};

export const isFreeTrialOver = (diffitUser) => {
  if (!diffitUser) return false;
  return diffitUser.is_free_trial_over;
};

export const isNearingTrialEnd = (diffitUser) => {
  if (!diffitUser) return false;
  return diffitUser.is_nearing_trial_end;
};

export const isInPremiumOrg = (diffitUser) => {
  // Check the subscription end date of each org the user is in
  // If any of them are in the future, the user is premium
  if (!diffitUser) return false;
  return diffitUser.is_in_premium_org;
};

export const isActiveIndividualSubscription = (diffitUser) => {
  if (!diffitUser) return false;
  return diffitUser.is_active_individual_subscription;
};

export const hasActiveSubscription = (diffitUser) => {
  return isInPremiumOrg(diffitUser) || isActiveIndividualSubscription(diffitUser);
};

export const isFreeTrialUser = (clerkUser, diffitUser) => {
  if (!clerkUser) return false;
  if (diffitUser) {
    return diffitUser.is_free_trial;
  }
  const now = Date.now();
  // If the user was created in the past day, they're premium
  // There's a small gap between creating the user in Clerk and then updating on the backend, so give the benefit of the doubt
  if (clerkUser.createdAt > now - ONE_DAY) {
    return true;
  }
  return false;
};

export const isPremiumUser = (user, diffitUser) => {
  return hasActiveSubscription(diffitUser) || isFreeTrialUser(user, diffitUser);
};

export const daysLeftInTrial = (clerkUser, diffitUser) => {
  if (!clerkUser) return null;
  if (diffitUser) {
    return diffitUser.days_left_in_trial;
  }

  const now = Date.now();

  // If the user was created in the past day, they have 60 days left in their trial
  // There's a small gap between creating the user in Clerk and then updating on the backend, so give the benefit of the doubt
  if (clerkUser.createdAt > now - ONE_DAY) {
    const daysLeft = 60;
    return daysLeft;
  }
  return null;
};
