import { SignedIn, SignedOut } from '@clerk/clerk-react';
import Base from './routes/base';
import IsDiffitAdmin from './components/AdminTools/IsDiffitAdmin';
import ErrorPage from './components/ErrorPage';
import Maintenance from './components/Maintenance';

import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { Redeem } from './routes/redeem';
import { Activities } from './routes/Activities/activities';
import { useEffect } from 'react';
import WebFont from 'webfontloader';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Base />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        async lazy() {
          let { Start } = await import('./routes/start');
          return { Component: Start };
        },
      },
      {
        path: '/packet/:packetId',
        async lazy() {
          let { Packet } = await import('./routes/packet');
          return { Component: Packet };
        },
      },
      {
        path: '/packet/:packetId/:activityId',
        async lazy() {
          let { Packet } = await import('./routes/packet');
          return { Component: Packet };
        },
      },
      {
        path: '/activity/:activityId',
        async lazy() {
          let { Activity } = await import('./routes/Activities/activity');
          return { Component: Activity };
        },
      },
      {
        path: '/activity/:activityId/:packetId',
        async lazy() {
          let { Activity } = await import('./routes/Activities/activity');
          return { Component: Activity };
        },
      },
      {
        path: '/activity/:activityId/:packetId/customize',
        async lazy() {
          let { CustomizeActivity } = await import('./routes/Activities/customize');
          return { Component: CustomizeActivity };
        },
      },
      {
        path: '/auth',
        async lazy() {
          let { Auth } = await import('./routes/auth');
          return { Component: Auth };
        },
      },
      {
        path: '/subscription',
        async lazy() {
          let { ManageStripeSubscription } = await import('./routes/subscription');
          return { Component: ManageStripeSubscription };
        },
      },
      {
        path: '/redeem',
        async lazy() {
          let { Redeem } = await import('./routes/redeem');
          let { Start } = await import('./routes/start');
          return {
            Component: () => (
              <Redeem>
                <Start />
              </Redeem>
            ),
          };
        },
      },
      {
        // LEGACY; replaced by /redeem above
        path: '/promo',
        async lazy() {
          let { EnterPromoCode } = await import('./routes/promo');
          let { Start } = await import('./routes/start');
          return {
            Component: () => (
              <EnterPromoCode>
                <Start />
              </EnterPromoCode>
            ),
          };
        },
      },
      {
        path: '/admin',
        async lazy() {
          let { Admin } = await import('./routes/admin');
          return {
            Component: () => (
              <>
                <IsDiffitAdmin>
                  <Admin />
                </IsDiffitAdmin>
                <SignedOut>
                  <Navigate to='/' replace />
                </SignedOut>
              </>
            ),
          };
        },
      },
      {
        path: '/resources',
        async lazy() {
          let { Resources } = await import('./routes/resources');
          return {
            Component: () => (
              <>
                <SignedIn>
                  <Resources />
                </SignedIn>
                <SignedOut>
                  <Navigate to='/' replace />
                </SignedOut>
              </>
            ),
          };
        },
      },
      {
        path: '/activities',
        element: (
          <>
            <Activities />
          </>
        ),
      },
      {
        path: '/activities/:packetId',
        element: (
          <>
            <SignedIn>
              <Activities />
            </SignedIn>
            <SignedOut>
              <Navigate to='/' replace />
            </SignedOut>
          </>
        ),
      },
      {
        path: '/admin/templates',
        async lazy() {
          let { Templates } = await import('./routes/templates');
          return {
            Component: () => (
              <>
                <IsDiffitAdmin>
                  <Templates isAdmin={true} />
                </IsDiffitAdmin>
                <SignedOut>
                  <Navigate to='/' replace />
                </SignedOut>
              </>
            ),
          };
        },
      },
      {
        path: '/templates',
        async lazy() {
          let { Templates } = await import('./routes/templates');
          return {
            Component: () => (
              <>
                <Templates />
                <SignedOut>
                  <Navigate to='/' replace />
                </SignedOut>
              </>
            ),
          };
        },
      },
      {
        path: '/org/:orgId',
        async lazy() {
          // Capture SignedIn/SignedOut
          let { OrgDashboard } = await import('./routes/organization');
          return {
            Component: () => {
              return (
                <>
                  <SignedIn>
                    <OrgDashboard />
                  </SignedIn>
                  <SignedOut>
                    <Navigate to='/' replace />
                  </SignedOut>
                </>
              );
            },
          };
        },
      },
      {
        path: '/join/:orgId',
        async lazy() {
          let { JoinOrg } = await import('./components/JoinOrg');
          let { Start } = await import('./routes/start');
          return {
            Component: () => (
              <JoinOrg>
                <Start />
              </JoinOrg>
            ),
          };
        },
      },
      {
        path: '/pricing/:quoteId?',
        async lazy() {
          let { PricingPage } = await import('./components/Pricing/PricingPage');
          return {
            Component: () => <PricingPage />,
          };
        },
      },
    ],
  },
]);

export default function App() {
  const inMaintenanceMode = false;
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Nunito',
          'Tilt Warp',
          'Chelsea Market',
          'Spartan', 
          'League Spartan',
          'Barlow',
          'Londrina Solid',
          'Impact',
        ],
      },
      active: function () {
        console.log('fonts loaded');
      },
    });
    const handleCachedError = (event) => {
      if (event.message?.includes('Failed to fetch dynamically imported module')) {
        window.location.reload();
      }
    };
    window.addEventListener('error', handleCachedError);
    return () => {
      window.removeEventListener('error', handleCachedError);
    };
  }, []);
  return <>{inMaintenanceMode ? <Maintenance /> : <RouterProvider router={router} />}</>;
}
