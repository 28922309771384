import { BoltIcon } from '@heroicons/react/24/solid';

export default function PremiumIcon({ height, className = '' }) {
  const getHeight = () => {
    return height ? height : 'h-4';
  };

  return (
    <BoltIcon
      className={getHeight() + ` w-auto p-0.5 bg-purple-400 rounded-full text-white ${className}`}
    />
  );
}
