import CreateAxiosInstance from './axiosConfig';
const APIClient = CreateAxiosInstance(import.meta.env.VITE_API_BASE_URL);

export async function getCodes(searchQuery, page, perPage, sortBy, sortOrder, includeSchoolCodes) {
  return await APIClient.get(`/promo`, {
    params: {
      search_query: searchQuery,
      page: page,
      per_page: perPage,
      sort_by: sortBy,
      sort_order: sortOrder,
      include_school_codes: includeSchoolCodes,
    },
    customErrorHandler: function (error) {
      alert(error?.response?.data?.message || 'Error getting promo codes');
    },
  });
}

export async function updateCode(codeId, code) {
  return await APIClient.put(`/promo/${codeId}`, code, {
    customErrorHandler: function (error) {
      alert(error?.response?.data?.message || 'Error updating promo code');
    },
  });
}

export async function createCode(code) {
  return await APIClient.post(`/promo`, code, {
    customErrorHandler: function (error) {
      alert(error?.response?.data?.message || 'Error creating promo code');
    },
  });
}

export async function deleteCode(codeId) {
  return await APIClient.delete(`/promo/${codeId}`, {
    customErrorHandler: function (error) {
      alert(error?.response?.data?.message || 'Error deleting promo code');
    },
  });
}

export async function redeemCode(code, confirmedSchoolId) {
  return await APIClient.post(
    `/promo/redeem`,
    { promo_code: code, school_record_id: confirmedSchoolId },
    {
      customErrorHandler: function (error) {
        if (error?.response?.data?.error) {
          return;
        }
        alert('We ran into a problem redeeming your promo code. Please try again later.');
      },
    }
  );
}

export async function checkCode(code) {
  return await APIClient.get(`/promo/check`, {
    params: { promo_code: code }, // Pass promo_code as query parameter
    headers: {
      'Content-Type': 'application/json',
    },
    customErrorHandler: function (error) {
      console.log(error?.response?.data?.error);
    },
  });
}

export async function getUsersSchoolCode(schoolId) {
  return await APIClient.get(`/promo/get_users_school_code`, {
    params: {
      school_record_id: schoolId,
    },
    customErrorHandler: function (error) {
      alert(error?.response?.data?.message || 'Error getting school code');
    },
  });
}

export async function getUsersShareCode() {
  return await APIClient.get(`/promo/get_share_code`, {
    customErrorHandler: function (error) {
      alert(error?.response?.data?.message || 'Error getting share code');
    },
  });
}
