export const MyActivitiesIcon = ({ className = '' }) => {
  return (
    <div className={className}>
      <svg width='100%' height='100%' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19.875 10.125L19.9262 10.1763H19.9987H33.332C35.0687 10.1763 36.4904 11.598 36.4904 13.3346V30.0013C36.4904 31.738 35.0687 33.1596 33.332 33.1596H6.66536C4.92868 33.1596 3.50703 31.738 3.50703 30.0013L3.5237 10.0014V10.0013C3.5237 8.26347 4.92982 6.84297 6.66536 6.84297H16.5929L19.875 10.125ZM33.332 30.1763H33.507V30.0013V13.3346V13.1596H33.332H18.6879L15.4058 9.87756L15.3545 9.8263H15.282H6.66536H6.49036V10.0013V30.0013V30.1763H6.66536H33.332ZM28.157 18.3346C28.157 20.0713 26.7354 21.493 24.9987 21.493C23.262 21.493 21.8404 20.0713 21.8404 18.3346C21.8404 16.598 23.262 15.1763 24.9987 15.1763C26.7354 15.1763 28.157 16.598 28.157 18.3346ZM31.4904 26.668V28.1596H18.507V26.668C18.507 26.1782 18.7515 25.7362 19.1806 25.3407C19.6109 24.9441 20.215 24.6064 20.8992 24.3325C22.2677 23.7847 23.9123 23.5096 24.9987 23.5096C26.0851 23.5096 27.7297 23.7847 29.0982 24.3325C29.7824 24.6064 30.3865 24.9441 30.8168 25.3407C31.2459 25.7362 31.4904 26.1782 31.4904 26.668Z'
          strokeWidth='0.35'
        />
      </svg>
    </div>
  );
};
