import { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';

import PrimaryButton from './PrimaryButton';

import SignInModal from './SignInModal';
import LoadingSpinner from './LoadingSpinner';
import { Mixpanel } from '../services/Mixpanel';
import { useSearchParams } from 'react-router-dom';

export default function SignInButton() {
  const [query, setQuery] = useSearchParams();
  const { isLoaded, isSignedIn } = useUser();
  const [open, setOpen] = useState(false);
  const [startInSignUp, setStartInSignUp] = useState(false);

  function handleClick(willStartInSignUp = false) {
    setStartInSignUp(willStartInSignUp);
    Mixpanel.track('Log In Button');
    setOpen(true);
  }

  useEffect(() => {
    if (query.get('signup') && !isSignedIn && isLoaded) {
      setOpen(true);
      query.delete('signup');
      setStartInSignUp(true);
      setQuery(query);
    }
  }, [query, isLoaded, isSignedIn]);

  const shown = () => !isSignedIn && isLoaded;

  return (
    <>
      {/* <PrimaryButton text='Log In' shown={!isSignedIn & isLoaded} action={handleClick} /> */}
      <div className={shown() ? '' : 'hidden'}>
        <button
          type='button'
          onClick={() => handleClick(true)}
          className='rounded-md py-2 px-3 text-sm text-emerald-600 font-semibold border rounded border-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 hover:text-emerald-800 hover:border-emerald-600'>
          Create Free Account
        </button>
      </div>
      <div className={shown() ? '' : 'hidden'}>
        <button
          type='button'
          onClick={() => handleClick()}
          className='py-2 px-3 text-sm text-emerald-600 hover:text-emerald-800 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600'>
          Sign In
        </button>
      </div>
      {!isLoaded && <LoadingSpinner />}
      <SignInModal open={open} setOpen={setOpen} startInSignUp={startInSignUp} />
    </>
  );
}
