import { XMarkIcon } from '@heroicons/react/20/solid';

export default function ModalX({ setOpen }) {
  return (
    <div className='absolute right-0 top-0 pr-4 pt-4'>
      <button
        type='button'
        autoFocus={false}
        className='rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'
        onClick={() => setOpen(false)}>
        <span autoFocus={false} className='sr-only'>
          Close
        </span>
        <XMarkIcon autoFocus={false} className='h-6 w-6' aria-hidden='true' />
      </button>
    </div>
  );
}
