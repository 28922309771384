import CreateAxiosInstance from './axiosConfig';
const APIClient = CreateAxiosInstance(import.meta.env.VITE_API_BASE_URL);

export async function getSchoolRecords({ searchQuery, page, perPage, sortBy, sortOrder }) {
  return await APIClient.get('/school-records/', {
    params: {
      search_query: searchQuery,
      page,
      per_page: perPage,
      sort_by: sortBy,
      sort_order: sortOrder,
    },
  });
}

export async function getSchoolRecordsByLocation({ city, state, country, lat, lon, exclude_districts }) {
  return await APIClient.get(`/school-records/get_schools_in_city`, {
    params: {
      city,
      state,
      country,
      lat,
      lon,
      exclude_districts,
    },
    customErrorHandler: function (error) {
      alert(error?.response?.data?.message || 'Error getting organizations in zipcode');
    },
  });
}

export async function connectUserToExistingSchoolRecord({ school_id }) {
  return await APIClient.patch(`/school-records/connect`, {
    school_id,
  });
}

export async function createSchoolRecordAndConnect({ name, city, state, country, is_district }) {
  return await APIClient.post(`/school-records/connect`, {
    name,
    city,
    state,
    country,
    is_district: is_district,
  });
}

export async function processSchoolRecordsURL({ spreadsheet_url }) {
  return await APIClient.patch('/school-records/bulk_update', { spreadsheet_url }, { timeout: 0 });
}

export async function addSchoolRecord({ name, city, state, country, enrollment, is_district }) {
  return await APIClient.post(
    `/school-records/`,
    {
      name,
      city,
      state,
      country,
      enrollment,
      is_district,
    },
    {
      customErrorHandler: function (error) {
        alert(error?.response?.data?.message || 'Error adding school record');
      },
    }
  );
}

export async function editSchoolRecord({
  school_record_id,
  salesforce_id,
  name,
  city,
  state,
  country,
  enrollment,
  is_district,
}) {
  return await APIClient.patch(
    `/school-records/`,
    {
      school_record_id,
      salesforce_id,
      name,
      city,
      state,
      country,
      enrollment,
      is_district,
    },
    {
      customErrorHandler: function (error) {
        alert(error?.response?.data?.message || 'Error updating school record');
      },
    }
  );
}

export async function getSchoolRecordBySalesforceId({ salesforceId }) {
  return await APIClient.get(`/school-records/salesforce_id?salesforce_id=${salesforceId}`);
}

export async function mergeSchoolRecords({ primarySchoolId, otherSchoolIds }) {
  return await APIClient.post(`/school-records/merge`, {
    primary_school_id: primarySchoolId,
    other_school_ids: otherSchoolIds,
  });
}

export async function deleteUserSchoolRecord({ schoolRecordId }) {
  const data = { school_id: schoolRecordId };
  return await APIClient.delete(`/school-records/connect`, {
    data: data,
  });
}
