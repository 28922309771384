export const getSchoolFromUser = (diffitUser) => {
  return diffitUser?.school_records?.[0] || {};
};

export const getStateFromUsersSchool = (diffitUser) => {
  const school = getSchoolFromUser(diffitUser);
  return school?.state || '';
};

export const getLowestSupportedGradeLevel = (diffitUser) => {
  // grade_levels contains a list of ranges, e.g., ["K-2", "3-5"]
  // They aren't necessarily sorted
  // Return the lowest grade level as a number

  if (!diffitUser?.grade_levels || diffitUser.grade_levels.length === 0) {
    return null;
  }

  const gradeLevels = diffitUser.grade_levels.map((gradeLevel) => gradeLevel.grade_level);

  if (gradeLevels.includes('K-2')) {
    return 2;
  } else if (gradeLevels.includes('3-5')) {
    return 3;
  } else if (gradeLevels.includes('6-8')) {
    return 6;
  } else if (gradeLevels.includes('9-12')) {
    return 9;
  }
};

export const userHasGoogleAccount = (user) => {
  let googleAccount = user.externalAccounts.find((account) => account.provider === 'google');
  let hasGoogleAccount = !!googleAccount;
  return hasGoogleAccount;
};

export const constructMailToLinkForUser = ({ user, toAddress = '', subject = '', body = '' }) => {
  if (user && userHasGoogleAccount(user)) {
    // if the user is signed in with google, open a link straight to gmail
    return `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&body=${body}&to=${toAddress}`;
  } else {
    // otherwise, use the default mail client
    return `mailto:?subject=${subject}&body=${body}&to=${toAddress}`;
  }
};
