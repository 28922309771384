import CreateAxiosInstance from './axiosConfig';
const APIClient = CreateAxiosInstance(import.meta.env.VITE_API_BASE_URL);

export async function addUser(packetId, token) {
  return await APIClient.post(
    '/user',
    { packet_id: packetId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      customErrorHandler: function (error) {
        alert(
          error?.response?.data?.message ||
            'There was an error logging you in. Please try again later.'
        );
      },
    }
  );
}

export async function markUserNuxAsSeen(userId, seenNux) {
  return await APIClient.post(
    `/user/${userId}/nux`,
    { seen_nux: seenNux },
    {
      customErrorHandler: 'There was an error logging you in. Please try again later.',
    }
  );
}

export async function getCurrentUser() {
  return await APIClient.get('/user', {
    customErrorHandler: function (error) {
      alert(
        error?.response?.data?.message ||
          'There was an error logging you in. Please try again later.'
      );
    },
  });
}

export async function isGoogleOAuthTokenValid() {
  return await APIClient.get('/user/valid_google_oauth_token', {
    customErrorHandler: function (error) {
      alert(
        error?.response?.data?.message ||
          'There was an error logging you in. Please try again later.'
      );
    },
  });
}

export async function shareDiffitWithEmails(emails) {
  return await APIClient.post(
    '/user/share',
    { emails: emails },
    {
      customErrorHandler: function (error) {
        alert(error?.response?.data?.message || 'Error sharing Diffit');
      },
    }
  );
}

export async function reportContent(resourceType, content) {
  return await APIClient.post(
    `/user/report`,
    { resource_type: resourceType, content: content },
    {
      customErrorHandler: function (error) {
        alert(error?.response?.data?.message || 'Error reporting content');
      },
    }
  );
}

export async function getDismissedInputFlags() {
  return await APIClient.get('/user/dismissed_input_flags', {
    customErrorHandler: function (error) {
      alert(
        error?.response?.data?.message ||
          'There was an error fetching dismissed input flags. Please try again later.'
      );
    },
  });
}

export async function dismissInputFlag(userId, flag) {
  return await APIClient.post(
    `/user/${userId}/dismissed_input_flags`,
    { input_flag: flag },
    {
      customErrorHandler: function (error) {
        alert(
          error?.response?.data?.message ||
            'There was an error dismissing the input flag. Please try again later.'
        );
      },
    }
  );
}

export async function removeDismissedInputFlag(userId, flag) {
  return await APIClient.delete(`/user/${userId}/dismissed_input_flags/${flag}`, {
    customErrorHandler: function (error) {
      alert(
        error?.response?.data?.message ||
          'There was an error removing the dismissed input flag. Please try again later.'
      );
    },
  });
}

export async function submitSurveyTrialExtension(survey) {
  return await APIClient.post(
    '/user/survey',
    {
      survey_response: survey,
    },
    {
      customErrorHandler: function (error) {
        alert(error?.response?.data?.message || 'Error submitting survey');
      },
    }
  );
}
