import React from 'react';
import { ClerkProvider } from '@clerk/clerk-react';
import Clerk from '@clerk/clerk-js';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import './index.css';
import App from './App';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'local',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.05, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

window.addEventListener('vite:preloadError', (event) => {
  window.location.reload();
});

const clerkPubKey = import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY;
const LDProvider = withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
})(App);

export let clerk = new Clerk(import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY);
const initialClerkLoad = clerk.load;

clerk.load = async () => {
  let retries = 0;
  let maxRetries = 5;
  let retryInterval = 1000;
  let loadWithRetry = async () => {
    while (retries < maxRetries) {
      try {
        const initialLoadResult = await initialClerkLoad();
        return initialLoadResult;
      } catch (error) {
        retries++;
        await new Promise((resolve) => setTimeout(resolve, retryInterval));
      }
    }
  };
  return await loadWithRetry();
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <ClerkProvider Clerk={clerk} publishableKey={clerkPubKey}>
    <React.StrictMode>
      <SpeedInsights />
      <LDProvider />
    </React.StrictMode>
  </ClerkProvider>
);
