import React, { useContext } from 'react';
import { DiffitUserContext } from '../../contexts/DiffitUserContext';

const IsDiffitAdmin = ({ children }) => {
  const { diffitUser } = useContext(DiffitUserContext);

  if (!diffitUser || diffitUser?.is_diffit_admin) {
    return React.createElement(React.Fragment, null, children);
  } else {
    window.location.href = '/';
  }
  return null;
};

export default IsDiffitAdmin;
