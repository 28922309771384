import { useRouteError, useSearchParams } from 'react-router-dom';
import Header from './Header';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

export default function ErrorPage() {
  const [isHandlingError, setIsHandlingError] = useState(true);
  const error = useRouteError();
  console.error(error);

  useEffect(() => {
    // Capture the exception with Sentry
    captureException(error, { level: 'fatal' });

    // Function to determine if the error is related to dynamic imports
    const isDynamicImportError = () => {
      return error?.message?.includes('Failed to fetch dynamically imported module');
    };

    if (isDynamicImportError()) {
      const hasReloaded = sessionStorage.getItem('hasReloaded');

      if (!hasReloaded) {
        console.log('Reloading to clear dynamically imported module error');

        // Set the flag in sessionStorage to indicate that reload has occurred
        sessionStorage.setItem('hasReloaded', 'true');

        // Reload the page
        window.location.reload();
      } else {
        // Optionally, you can clear the flag if you want to allow future reloads
        sessionStorage.removeItem('hasReloaded');
        console.log('Reload already attempted. Not reloading again.');
      }
    }
    setIsHandlingError(false);
  }, [error]);

  return (
    <>
      <Header />
      {!isHandlingError && (
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='mx-auto max-w-4xl'>
            <div id='error-page' className='my-8 grid gap-2'>
              <h1 className='text-lg text-emerald-900 font-bold'>Oops!</h1>
              <p>Sorry, an unexpected error has occurred.</p>
              <p>
                <i>{error.statusText || error.message}</i>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
